
.card-body{
  width: 100%;
}
.datePickerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

  
.datePickerInputWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.datePickerInput {
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
  
.datePickerInput:focus {
    color: #495057;
    background-color: #fff;
    border-color: #6E32FA;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(95, 39, 205, 0.25);
}
  
.timePickerWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}
  
.timePickerOption {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-align: center;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}
  
.timePickerOption:hover {
    border-color: #6E32FA;
}
  
.timePickerOption.selected {
    color: #fff;
    background-color: #6E32FA;
    border-color: #6E32FA;
}
  
#confirmation {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
  
#confirmation:focus {
    color: #495057;
    background-color: #fff;
    border-color: #6E32FA;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(95, 39, 205, 0.25);
}
  
.btn-primary {
    background-color: #6E32FA;
    border-color: #6E32FA;
}
  
.btn-primary:hover {
    background-color: #44337a;
    border-color: #44337a;
}
  /* Remove the default styles */
.react-datepicker-popper {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.react-datepicker {
  font-family: inherit;
}

.react-datepicker__input-container {
  display: block;
  position: relative;
}

.react-datepicker__input-container input {
  padding: 8px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  font-size: 16px;
  color: #333;
  width: 100%;
}

.react-datepicker__input-container input:focus {
  border-color: #6E32FA;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  margin: 0;
}

.react-datepicker__day-name,
.react-datepicker__day {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  margin: 2px;
  border-radius: 50%;
}

.react-datepicker__day:hover,
.react-datepicker__day--selected {
  background-color: #6E32FA;
  color: white;
}

.react-datepicker__day--selected:hover {
  background-color: white;
  color: #6E32FA;
}
.react-datepicker__header{
    background-color: #F8F9FA;
}

.react-datepicker__day--keyboard-selected{
    background-color: #6730ec4d;
}

.agendarBtn{
    width: fit-content;
    height: 2.2rem;
    margin-top: 1.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.agendarBtn i{
  margin-right: 0.5rem;
}
.agendarBtn p{
  margin: 0;
  padding: 0;
}
.datePickerInput:hover{
  cursor: pointer !important;
}
.input-confirmation:hover{
  cursor: auto !important;
}

/* DatePicker container */

.agenda-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 60%;
  margin: auto;
}

.agenda-container h5{
  padding: 1.5rem 0.5rem;
  margin: 0rem;
}

.schedule-card{
  background-color: white;
  margin: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  height: fit-content;
  border-radius: 1rem;
}

/* Info items */

.info-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.info-item{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.2rem 0.6rem;
}

.info-item p{
  margin:0px 0.4rem;
  padding: 0px;
  font-size: 0.89rem;
  line-height: 1.2rem;
}
.info-item i{
  color: #6E32FA;
}

.time-spinner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0rem;
  color: #6E32FA;
}

@media screen and (max-width: 810px){
  .agenda-container{
    width: 90%;
  }
  .agenda-container .title {
    display: flex;
    justify-content: center;
  }
  .info-container{
    margin: 0.25rem auto;
    flex-wrap: wrap;
  }
  .info-container .info-item{
    margin: 0;
  }
  .agendarBtn{
    margin: auto;
    margin-top: 1.8rem;
  }
  .schedule-card{
     margin-bottom: 2rem;
  }
}