@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../assets/scss/custom.scss';

//Import Light mode
@import '../assets/scss/bootstrap.scss';
@import '../assets/scss/style.scss';


//Import Dark RTL mode
// @import './assets/scss/bootstrap-dark.scss';
// @import './assets/scss/style-dark.scss';
// @import './assets/scss/style-rtl.scss';

//Import RTL mode
// @import './assets/scss/bootstrap.scss';
// @import './assets/scss/style.scss';
// @import './assets/scss/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss/bootstrap-dark.scss';
// @import './assets/scss/style-dark.scss';

//features scss
@import "../assets/scss/features"; 

.body-hub h6 {
  font-family: 'Krub', sans-serif;
  color: #707070;
  align-self: flex-start;
}

// drawer
.react-drawer-drawer {
  z-index: 9999;
  background: #fff !important;
  width: 350px !important;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  // transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  // transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.react-drawer-overlay {
  z-index: 1050;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #0a58ca;
  background-color: transparent;
}

.custom-badge-color {
  &.bg-soft-primary {
    color: $primary;
    background-color: rgba($primary, 0.1) !important;
  }
}

.carousel-overlay {
  .carousel-item {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $overlay;
    }
  }
}

.wicon a i {
  align-items: center;
  display: flex;
}

#style-switcher {
  transition: all .4s;
  background: none repeat scroll 0 0 $white;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  left: -189px;
  position: fixed;
  top: 11%;
  width: 189px;
  z-index: 9999;
  padding: 10px 5px;

  .bottom a.settings {
    background: none repeat scroll 0 0 $white;
    height: 41px;
    position: absolute;
    right: -41px;
    top: 100px;
    width: 40px;
    padding: 3px;
    border-radius: 0 30px 30px 0;
  }
}

.cookie-popup.alert {
  .btn-close {
    top: 17px;
    right: 17px;
  }
}

.l-dark {
  height: 6.7vh ;
}

.l-light {
  height: 6.7vh ;
}

.home-wrapper:before{
  z-index: 0;
}

.company-icon{
  max-height: 200px !important;
}

.company-icon-container{
  max-height: 10vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0rem 2.3rem 0rem 2.3rem !important;
}

.grayscale { filter: grayscale(100%); }

.bg-login{
  padding: 10.5vh 0vh;
}

.bg-auth-home{
  padding: 3.9vh 0vh !important;
}


@media (max-width: 575px){
  .section{
      padding: 0px 20px !important;
  }
  #topnav{
    padding: 0px 20px !important;
}
}

@media(max-width: 768px){
  #psicologos-animation{
    width: 80vw !important;
    margin: auto;
}
.title-heading{
  margin: auto !important;
  width: 80vw !important;
}
.home-wrapper{
  padding-top: 4rem !important;
}
.auth-svg{
  display: none !important;
}
.bg-login{
  padding: 0vh;
}

.bg-auth-home{
  padding: 0vh;
}


}

@media (min-width: 400px) and (max-width: 768px){
  .auth-form-login{
    position: absolute;
    top: 15%;
    width: auto;
    padding: 0rem 4rem;
  }
}


.auth-logo {
  height: 40px;
}

.auth-svg{
  height: 35vw;
}

.appointment-card{
  position: absolute;
  top: 10%;
  left: 30%;
  height: 75vh;
  width: 50vw;
}
 // Errors

 .main-error-alert {
  position: absolute;
  top:0;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: max-content; 
  z-index: 3;
  animation: slide-in 0.5s ease-in-out;
}

@keyframes slide-in {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}


input[readonly]{
  background: rgb(243, 243, 243) !important;
}
input[readonly]:hover{
  cursor: default !important;
}

@media screen and (max-width: 930px){
  .topbar-logo{
    height: 5vh;
  }
}


@media screen and (max-width: 995px){
  .buy-button{
    display: none !important;
  }

  .logo img{
    height: 5vh !important;
  }
}