
.toastui-calendar-allday{
  display: none !important;
}

.toastui-calendar-layout{
  max-width: 80vw !important;
  margin: 0 auto !important;
  border-radius: 15px !important;
  box-shadow: 0 0 10px rgba(0,0,0,0.1) !important;
}


.toastui-calendar-time{
  height: 90% !important;
}

.calendar-container .container{
  max-width: 99% !important;
  height: 80vh !important;
}

.toastui-calendar-day-name__date{
  font-size: 1.3rem !important;
}

.toastui-calendar-day-name__name{
  font-size: 0.6rem !important;
}

.toastui-calendar-event-time-content{
  text-wrap: wrap !important;
}

.calendar-controls{
  display: flex !important;
  background: white !important;
  padding: 0.5rem;
  border-radius: 15px !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 98% !important;
  margin: auto !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  box-shadow: 0 0 10px rgba(0,0,0,0.1) !important;
}

.calendar-controls button{
  font-size: 0.8rem;
}

.toastui-calendar-day-view .toastui-calendar-panel:not(.toastui-calendar-time), .toastui-calendar-week-view .toastui-calendar-panel:not(.toastui-calendar-time) {
  overflow-y: clip !important;
}



/* Modal */
.modal-dialog{
  width: 60%;
  max-width: initial !important;
} 
.modal-header .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat !important;  
}

@media screen and (max-width: 1040px){
  .toastui-calendar-day-name__name{
     display: none !important;
  }
  .calendar-controls{
    width: 80vw !important;
  }
  .toastui-calendar-layout{
    width: 80vw !important;
    max-width: 80vw !important;
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 809px){
  .toastui-calendar-day-name__name{
     display: none !important;
  }

  .toastui-calendar-columns{
    left: 42px !important;
  }
  .toastui-calendar-timegrid-time-column{
    width: 42px !important;
  }
 
}

@media screen and (max-width: 600px){
  .toastui-calendar-day-name__date{
    font-size: 1rem !important;
  }
  .toastui-calendar-day-name__name{
    font-size: 0.5rem !important;
  }
  .agregarHorarioNoDisponibleBtn{
    display: none;
  }
  .calendar-container .container{
    height: 72vh !important;
  }
  .calendar-controls{
    width: 90vw !important;
  }
  .toastui-calendar-layout{
    width: 90vw !important;
    max-width: 90vw !important;
    margin: 0 auto !important;
  }
}