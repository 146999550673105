.review-section{
    height: 100vh;
    width: 100vw;
    background-color: rgb(239, 239, 239);
    padding: 4rem 0rem;
}
.review-form-header{
    width: 50vw;
}
.review-form-container{
    margin: auto;
    max-width: 65%;
    min-height: 85%;
    padding: 2rem;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.review-from{
    margin: 0 auto;
    margin-top: 1rem;

}
.review-from textarea{
    height: 30vh;
    width: 50vw;
    border: 1.4px solid #ced4da;
    border-radius: 1rem;
    font-size: 1rem;
}


.enviarOpinionBtn{
    margin: 2.5rem 0;
    font-size: 0.8rem;
}


 /* Stars */

 button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .on {
    color: #6E32FA;
  }
  .off {
    color: #ccc;
  }