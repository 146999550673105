.psicologo-card{
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    margin: 0.7rem 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: auto;
    width: 55vw;
}

.psicologo-card:hover {
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);

}

.psicologo-card:before {
    content: "";
    border: 0.5px solid #d3d3d3;
    align-self: stretch;
}

.personal-information {
    order: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    padding-left: 2.1rem;
}

.psicologo-information {
    flex-basis: 70%;
    padding: 1.3rem;
    height: 100%;
}

.psicologo-image{
    margin-bottom: 2rem;
}

.psicologo-image img{
    width: 5rem;
   height: 5rem;    
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
}

.psicologo-data p{
   font-size: 0.8rem;
   margin: 0;
   padding: 0;
}
.psicologo-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
 }
 .psicologo-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;
 }
 .psicologo-name span{
   margin-right: 0.3rem;
}
 .psicologo-desc-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
 .psicologo-action{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
 }
 .psicologo-actionBtn{
    margin: 0rem 0.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
 }
 .psicologo-actionBtn p{
    margin: 0px;
    margin-left: 0.25rem;
    padding: 0px;
 }
 .psicologo-actionBtn i{
    font-size: 1.2rem;

 }
 .psicologo-descripcion{
    padding: 0.8rem 0rem;
 }

 .psicologo-descripcion p{
   font-size: 1rem;
}

 .psicologos-container{
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-bottom: 1rem;
 }

.cards-container{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
}

.title{
   width: 100%;
}


.filters-contaiener{
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   max-width: 100%;
   margin: auto;
   flex-wrap: wrap;
}



.search-bar{
   margin: 0.5rem 0rem;
   width: 50%;
   box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.search-bar button{
   font-size: 0.8rem;
}

.gender-filter{
   margin: 0rem 0.5rem;   
}
.gender-filter select{
   font-size: 0.8rem;
}

.title h5{
      font-size: 1.3rem;
      margin: auto;
      width: fit-content;
      padding: 1rem 0rem;
      margin-top: 1rem;
      font-weight: 600;
}
.especialidades-filter{
   margin: 0rem 0.5rem;
   margin-right: 0rem;
}
.especialidades-filter div{
   max-width: 28vw;
}
@media screen and (max-width: 1000px){
   .psicologo-card{
      flex-direction: column;
      font-size: smaller;
      margin-left: 0.5rem;
      
  }
  .personal-information {
   flex-direction: row;
   }
   .psicologo-data{
      padding: 0rem 0.5rem;
      margin: 0;
   }
   .psicologo-image{
      padding: 0rem 0.5rem;
      margin: 0;
   }
   .cards-container{
      align-items: center;
      justify-content: center;
   }
   .psicologo-action{
      justify-content: center;
   }

}
@media screen and (max-width: 730px){
   .psicologo-card{
      width: 93%;  
  }
  .cards-container .title h5{
      padding: 0rem 1rem;
      margin: 0.5rem auto;
      font-size: 1.3rem !important;
   }
   .psicologos-container{
     margin: 0.5rem;
    }
    .search-bar{
     width: 75%;
     
   }
   .filters-contaiener .especialidades-filter{
      margin-right: 0rem;
      margin-top: 0rem;
      margin-left: 0rem;
      max-width: 75%;
   }
   .especialidades-filter div{
      max-width: initial;
   }
   .filters-contaiener{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      margin: auto;
      flex-wrap: wrap;
   }
}