.citas-section-container{
    padding: 1rem;
    width: 100%;
    max-height: 100%;
}

.cita-card{
    margin: 0.6rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 100%;
    border-radius: 1rem !important;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2) !important;
}

.cita-card:hover{
    cursor: pointer;
    background-color: rgb(245, 245, 245);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3) !important;
}

.cita-card img{
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-right: 1rem;
    object-fit: cover;
}

.card-img{
    flex-basis: 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.body-card{
    flex-basis: 66.66%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.body-card p{
    margin: 0;
    padding: 0;
}

.citas-container{
    margin: auto;
    max-width: 70%;
    min-height: 64vh;
    padding: 2rem;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: 0 0 10px rgba(0,0,0,0.1) !important;
}
.citas-container-items{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.citas-title{
    margin: auto;
    width: 70%;
}
.citas-title h5{
    margin: 0;
    color: black;
    font-weight: 600;
}


.card-name{
    font-size: 1rem;
    color: black;
    font-weight: 600;
}

.card-date{
    font-size: 0.8rem;
    font-weight: 400;
    color: rgb(60, 60, 60);
}

.card-status{
    font-size: 0.8rem;
    font-weight: 400;
}

.card-icon i {
    color: black;
}


@media screen and (max-width: 1040px){
    .citas-container{
        min-width: 95%;
        min-height: 60vh;
    }
    .citas-title{
        min-width: 95%;
    }
}

.citas-pagination{
    margin-top: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

/* Citas filter */

.estado-filter {
    margin: 1rem 0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 0.7rem;
    padding-left: 0.7rem;
}
.estado-filter p {
    margin: 0rem 0.3rem;
}

.dropdown-item:hover {
    color: #6730EC !important;
}

.no-citas-text {
    align-self: center;
}

.pagination {
    max-width: 55vw !important;
    flex-wrap: wrap !important;
}

@media screen and (max-width: 730px){
    .citas-container{
        padding: 2rem 0rem;

    }
 }

 @media screen and (max-width: 930px){
    .estado-filter p {
        font-size: 0.9rem;
    }
 }