.chat-container{
    display: flex;
    height: 100%;
    width: 100%;
    padding: 1rem;
    margin: auto;
    column-gap: 1rem;
}

.str-chat{
    font-family: 'Nunito' !important;
    border-radius: 1rem !important;
    width: 100%;
}

.str-chat__main-panel{
    max-height: 85vh !important;
}

.str-chat__message-bubble{
    background-color: rgba(103, 48, 236, 0.11) !important;
}

.str-chat__message-reaction{
    background-color: rgba(103, 48, 236, 0.08) !important;
}

.str-chat__message-replies-count-button{
    color: #6E32FA !important;
}

.str-chat__message-input .str-chat__send-button svg path{
    fill: #6E32FA !important;
}

.str-chat-channel-list .str-chat__channel-list .str-chat__channel-list-react{
    height: 100% !important;
    max-height: 85vh !important;
}

.str-chat__channel-header{
    border-radius: 1rem !important;
}

.str-chat__message-input{
    border-radius: 1rem !important;
    margin-bottom: 0.5rem !important;
}

.str-chat__channel-preview-unread-badge{
    background-color: #6E32FA !important;
}

.str-chat__avatar .str-chat__avatar-image{
    background: none !important;
}

.channel-list-wrapper {
    max-width: 25% !important;
}

@media screen and (max-width: 529px) {
    .chat-container{
        padding: 0.5rem;
        column-gap: 0.5rem;
        height: 90% !important;
    }

    .toggle-channel-list-container{
        display: flex;
        align-items: center;
        width: 35%;
    }

    .toggle-channel-list-container .toggle-channel-list-icon {
        display: block;
        font-size: 2rem;
        margin: 0.5rem;
        cursor: pointer;

    }

    .channel-list-wrapper {
        display: none;
        max-width: 35% !important;
    }

    .channel-list-wrapper.visible {
        display: block;
        
    }

    .str-chat-channel-list .str-chat__channel-list .str-chat__channel-list-react{
        max-height: 80vh !important;
    }

    .str-chat__main-panel{
        max-height: 80vh !important;
    }
}




@media screen and (min-width: 530px) {

    .channel-list-wrapper {
        display: block !important;
    }
    .body-hub .toggle-channel-list-container {
        display: none;
    }
}
