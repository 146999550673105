.cita-section-container{
    padding: 2%;
  margin: auto;
    min-height: 100%;
    width: 90%;
}
.cita-container{
    margin: auto;
    max-width: 70%;
    max-height: 75%;
    padding: 1.5%;
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.cita-container-items{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.cita-container-items .cita-card{
    margin: 0.5rem 0rem;
    width: 100%;
    max-height: 4.7rem;
}



.especialista-section{
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    padding: 0.5rem 0rem;
}

.cita-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 0rem;
    margin: auto 0;
}

.cita-section .btn{
    margin-top: 1rem;
    width: 7rem;
    height: 100%;
}


.cita-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 0rem;
    width: 100%;
}
.cita-status img {
    height: 2.5rem;
}

.cita-status-text
{
    margin: 0 0.5rem;
}

.cita-status-text p
{
    font-size: 0.9rem;
    line-height: 1rem;
    margin: 0;
}
.cita-status-text-primary{
    font-weight: 600;
}

.cita-details{ 
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    border: white 5px solid;
    margin: 1rem 0rem;
}

.cita-details-item-icon{
    background-color: #E4E9F7;
    border-radius: 0.5rem;
    padding: 0.3rem;
    width: 2.3rem;
    height: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cita-details-item-icon i{
    color: gray;
    font-size: 1.5rem;
}

.cita-details-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: white 5px solid;
}

.cita-details-item-text p{
    margin: 0 1rem;
    font-size: 0.9rem;
}
.cita-details-item-text .text-secondary{

    font-size: 0.7rem;
}
.cita-details-item-text .text-primary{
    color: rgb(58, 58, 58) !important;
    font-weight: 600;
    font-size: 0.8rem;
}

.cita-btn-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cita-btn-container .btn{
    width: fit-content;
    min-width: 48%;
    height: 100% !important;
    font-size: 80%;
    margin: 0rem 0.5rem;
}

.btn-text{
    font-size: 0.8rem;
    max-height: fit-content;
}


@media screen and (max-width: 1320px){
    html{
        font-size: 18px;
    }
}


@media screen and (max-width: 1222px){
    html{
        font-size: 17px;
    }
    .cita-container{
        min-width: 80%;
        min-height: 85%;
    }
}
@media screen and (max-width: 854px){
    html{
        font-size: 16px;
    }
   
}
@media screen and (max-width: 809px){
    .cita-container{
        min-width: 90%;
        min-height: 95%;
    }
    .recompra-modal{
        width: 97% !important;
    }
   
}
@media screen and (max-width: 698px){
    html{
        font-size: 14px;
    }
    .cita-container{
        min-width: 100%;
        min-height: 100%;
        padding: 8%;
    }
}

