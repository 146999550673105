
:root{
    /* ===== Colors ===== */
    --body-color: #E4E9F7;
    --sidebar-color: #FFF;
    --primary-color: #6E32FA;
    --primary-color-light: #F6F5FF;
    --toggle-color: #DDD;
    --text-color: #707070;

    /* ====== Transition ====== */
    --tran-03: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.3s ease;
    --tran-05: all 0.3s ease;
}

body{
    min-height: 100vh;
    background-color: var(--body-color);
    transition: var(--tran-05);
}

::selection{
    background-color: var(--primary-color);
    color: #fff;
}

/* ===== Sidebar ===== */
 .sidebar{
    position: fixed;
    top: 10vh;
    left: 0;
    height: 100%;
    width: 250px;
    padding: 10px 14px;
    background: var(--sidebar-color);
    transition: var(--tran-05);
    box-shadow: 10px 0 10px -5px rgba(0, 0, 0, 0.1) !important;
    z-index: 1;
}

/* ===== Reusable code - Here ===== */
.sidebar li{
    height: 50px;
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
}

.sidebar header .image,
.sidebar .icon{
    min-width: 60px;
    border-radius: 6px;
}

.sidebar .icon{
    min-width: 60px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.sidebar .text,
.sidebar .icon{
    color: var(--text-color);
    transition: var(--tran-03);
}

.sidebar .text{
    font-size: 1rem;
    font-weight: 500;
    white-space: nowrap;
    opacity: 1;
}

.intercom-namespace .intercom-with-namespace-4gk9u0 > iframe {
    width: 100% !important;
}

/* =========================== */

.sidebar header{
    position: relative;
}

.sidebar header .image-text{
    display: flex;
    align-items: center;
}
.sidebar header .logo-text{
    display: flex;
    flex-direction: column;
}
header .image-text .name {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 600;
}

header .image-text .profession{
    font-size: 16px;
    margin-top: -2px;
    display: block;
}

.sidebar header .image{
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar header .image img{
    width: 40px;
    border-radius: 6px;
}

.sidebar header .toggle{
    position: absolute;
    top: 50%;
    right: -25px;
    transform: translateY(-50%) rotate(180deg);
    height: 25px;
    width: 25px;
    background-color: var(--primary-color);
    color: var(--sidebar-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    transition: var(--tran-05);
}

body.dark .sidebar header .toggle{
    color: var(--text-color);
}



.sidebar .menu{
    margin-top: 4.7rem;
}

.sidebar li.search-box{
    border-radius: 6px;
    background-color: var(--primary-color-light);
    cursor: pointer;
    transition: var(--tran-05);
}

.sidebar li.search-box input{
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--primary-color-light);
    color: var(--text-color);
    border-radius: 6px;
    font-size: 17px;
    font-weight: 500;
    transition: var(--tran-05);
}
.sidebar li a{
    list-style: none;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    text-decoration: none;
    transition: var(--tran-03);
}

.sidebar li a:hover{
    background-color: var(--primary-color);
}

li .active{
    background-color: var(--primary-color);
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text{
    color: var(--sidebar-color);
}
body.dark .sidebar li a:hover .icon,
body.dark .sidebar li a:hover .text{
    color: var(--text-color);
}

.sidebar .menu-bar {
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    justify-content: start;
    overflow-y: scroll;
}

.sidebar .menu-bar .menu .menu-links {
    padding-inline-start: 0.7rem !important;
}

.menu-bar::-webkit-scrollbar{
    display: none;
}
.sidebar .menu-bar .mode{
    border-radius: 6px;
    background-color: var(--primary-color-light);
    position: relative;
    transition: var(--tran-05);
}

.menu-bar .mode .sun-moon{
    height: 50px;
    width: 60px;
}

.mode .sun-moon i{
    position: absolute;
}
.mode .sun-moon i.sun{
    opacity: 0;
}
body.dark .mode .sun-moon i.sun{
    opacity: 1;
}
body.dark .mode .sun-moon i.moon{
    opacity: 0;
}

.menu-bar .bottom-content .toggle-switch{
    position: absolute;
    right: 0;
    height: 100%;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
}
.toggle-switch .switch{
    position: relative;
    height: 22px;
    width: 40px;
    border-radius: 25px;
    background-color: var(--toggle-color);
    transition: var(--tran-05);
}

.switch::before{
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    background-color: var(--sidebar-color);
    transition: var(--tran-04);
}

body.dark .switch::before{
    left: 20px;
}

.home{
    position: absolute;
    top: 9vh;
    left: 250px;
    height: 91vh;
    width: calc(100% - 250px);
    background-color: var(--body-color);
    transition: var(--tran-05);
    overflow: hidden;
}
.home .text{
    font-size: 30px;
    font-weight: 500;
    color: var(--text-color);
    padding: 12px 60px;
}

.chat-badge{
    font-family: 'Nunito' !important;
    font-size: 12px !important;
    width: 32% !important;
    height: 32% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0;
    margin-top: 20px !important;
}


body.dark .home .text{
    color: var(--text-color);
}


@media screen and (max-width: 1040px){
    .sidebar{
        width: 88px;
    }
    .sidebar .menu-bar .menu .menu-links {
        padding-inline-start: 0rem !important;
    }
    .sidebar .icon{
        font-size: 1.2rem;
    }
    .sidebar .text{
        opacity: 0;
    }
    .sidebar .toggle{
        transform: translateY(-50%) rotate(0deg);
    }
    .sidebar ~ .home{
        left: 78px;
        height: 100vh;
        width: calc(100% - 78px);
    }
    .citas-container{
        width: 100%;
    }
    .menu-links{
        padding: 0px;
    }
    .menu-links .nav-link{
        margin: 0;
    }
}


/* TopBar */

.main-head {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    height: 9vh;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    z-index: 999;
    position: relative;
  }
.topbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 9vh;
    padding: 2rem 0rem;
}

.topbar-container{
    display: flex;
}

.topbar-logo{
    height: 2.5rem;
    margin-left: 1.8rem;
}

.nav-link.active a .icon,
.nav-link.active a .text {
  color: var(--primary-color);
}

.body-hub{
    height: 91vh;
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (max-width: 430px){
    .sidebar{
        position: fixed;
        bottom: 0 !important;
        left: 0;
        top: unset;
        height: fit-content;
        width: 100%;
        padding: 10px 14px;
        background: var(--sidebar-color);
        transition: var(--tran-05);
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
        z-index: 100;
    }
    .sidebar .icon{
        font-size: 1.5rem;
    }
    .menu-links{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 0px;
    }
    .menu-bar{
        height: unset !important;
    }
    .sidebar .menu{
        margin-top: 0 !important;
    }
    .nav-link{
        width: fit-content !important;
    }
    .sidebar .nav-text {
        display: none !important;
    }
    .body-hub{
    height: 83vh !important;
    }
    .main-head{
        justify-content: flex-end;
    }
    .topbar-container {
        img{
            margin: auto 2rem;
        }
    }
    
}
    
