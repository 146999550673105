.cobros-section{
    padding: 0rem 2rem;
}

.cobros-container{
    margin: 2rem auto;
    max-width: 75%;
    min-height: 95%;
    padding: 2rem;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.cobros-title{
    align-self: flex-start;
}

.cobros-body{
    width: 50%;
    display: flex;
    justify-content: center;
    margin: 1rem auto;
}

.cobros-body-text{
    margin: 1rem auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.cobros-body-text i{
    margin-right: 0.5rem;
    color: #6e32fa;
}


.cobros-month i{
    color: #6e32fa;
}

.misCobrosLoader{
    width: 20rem;
}

.description-text{
    color: gray;
    font-size: 0.8rem;
    align-self: center;
    margin: 0rem;
}


.payment-brackets{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.payment-brackets h6{
    width: 39% !important;
    margin: 0 auto;
}

.progress-box{
    width: 30% !important;
}

.progress-box h5{
    text-align: left;
    margin: 0;
    margin-top: 1rem;
    font-size: 1rem;
}

.progress-box p{
    font-size: 0.7rem;
    text-wrap: nowrap;
}

.progressbar-container{
    display: flex;
    width: 40%;
    justify-content: space-around;
    align-items: center;
}

@media screen and (max-width: 1160px){
    .payment-brackets h6{
        width: 45% !important;
        margin: 0 auto;
    }

    .progressbar-container{
        display: flex;
        width: 46%;
        justify-content: space-around;
        align-items: center;
    }    
    .cobros-body{
        width: 60%;
    }
    
 }

@media screen and (max-width: 930px){
    .cobros-container{
        max-width: 95%;
    }
    .payment-brackets h6{
        width: 49% !important;
        margin: 0 auto;
    }

    .progressbar-container{
        display: flex;
        width: 52%;
        justify-content: space-around;
        align-items: center;
    }
    .cobros-body{
        width: 70%;
    }
 }

 @media screen and (max-width: 609px){
    .misCobrosLoader{
        width: 15rem;
    }
    .cobros-body{
        width: 100%;
    }
    .payment-brackets h6{
        width: 96% !important;
        margin: 0 auto;
    }

    .progressbar-container{
        display: flex;
        width: 98%;
        justify-content: space-around;
        align-items: center;
    }
 }