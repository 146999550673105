.ref-lins-container-offer{
    padding: 1.5rem 2rem;
}
.offer-container{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-top: 0;
}
.offer-info-container{
    display: flex;
}

.offer-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
}

.offer-title
{
    font-size: 1rem;
    margin: 0;
}

.offer-text{
    font-size: 0.8rem;
    margin: 0;
}

.offer-button-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
}

.btnOferta{
    width: 100%;
    height: 100%;
    font-size: 0.9rem;
}

.offer-icon{
    width: 5rem;
    height: 5rem;
}
