.mi-espacio-section{
    padding: 1rem;
}

.proxima-cita-section-container{
    margin: auto;
    width: 35vw;
}

.citacard-container{
    margin: 1rem 0rem;
    width: 70%;
}

.next-cita-container
{
    width: 100%;
    min-height: 85%;
    background-color: white;
    margin-top: 1rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.ref-lins-container{
    padding: 1rem;
      width: 100%;
    min-height: 85%;
    background-color: white;
    margin-top: 1rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.ref-link{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ref-icon{
    width: 2.5rem;
}

.ref-icon-container{
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 30%;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ref-icon-container:hover{
    background-color: #f2f2f2;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
}

.refs-text{
    margin: 0;
    margin-top: 0.5rem;
    font-size: 0.9rem;
}

.slider-container{
        width: 100%;
    background-color: white;
    margin-top: 1rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.slider{
    height: 10rem;
    width: 100%;

}

.slide{
    background-color:rgba(103, 48, 236, 0.1);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.slide-img{
    width: max-content;
}

/* SliderComponent.css */




@media screen and (max-width: 950px){
    .mi-espacio-section{
        padding: 0.8rem;
    }
    .ref-link{
        margin: 1rem 0rem !important;
    }
    .citacard-container{
        width: 80%;
    }
    .proxima-cita-section-container{
        width: 80vw;
    }
    .proxima-cita-section-container h5{
       display: none;
    }
    .next-cita-container{
        margin: 0rem;
    }
    .offer-title span{
        display: none;
    }
 }