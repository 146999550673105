.profile-picture-container {
    position: relative;
  }

  .profile-picture-container img {
    border-radius: 50% !important;
  }
  
  .edit-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(37, 37, 37);
    font-size: 1.5rem;
    opacity: 0.7;
    pointer-events: none; /* Para que no interfiera con los eventos del clic en la imagen */
  }
  
  .darken {
    filter: brightness(0.4);
    opacity: 0.3;
    border: 0.15rem solid #6E32FA;
  }

  .preview-image{
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    margin: auto;
  }

  .preview-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }