.time_messege{
    font-size: 1rem;
}
.footer_timer{
    z-index: 995;
    cursor: pointer;
}
.footer_timer #clockdiv {
    /* background-color: #5700a5; */
    background-color: #342065;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    display: inline-block;
    text-align: center;
    font-weight: 800;
    text-align: center;
    padding: 0.5rem 0rem;
  }
  
  .footer_timer #clockdiv > div {
    padding: 10px;
    display: inline-block;
  }
  .setpadding {
    font-size: 1.4em;
    padding-top: 3%;
  }
  .footer_timer #clockdiv div > span {
    border-radius: 3px;
    background: #fafafa;
    margin: 0 5px;
    padding: 3px 6px;
    display: inline-block;
  }
  
  .footer_timer .smalltext {
    /* padding-top: 5px; */
    font-size: 10px;
    font-weight: 600;
    color: #d1d1d1;
  }
  .footer_timer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #5700a5;
  }
  .footr_buy {
    background-color: #ffd23f;
    color: #240043;
    padding: 5px 15px;
    border-radius: 6px;
  }
  .dot_time {
    color: #fff;
    padding: 0;
    margin: 0;
    margin: 0 -11px;
  }
  .offercopy {
    background-color: #f72585;
    color: #fff;
    padding: 4px 13px;
    border: 2px dashed #ffc400;
    border-radius: 7px;
    margin: 0 5px;
    cursor: pointer;
  }
@media screen and (max-width: 768px) {
    .footer_timer #clockdiv {
        padding: 0.5rem 0rem;
    }
    .time_messege{
        font-size: 1.5rem;
        margin: 0;
        line-height: 1.5;
    }
    .offercopy {
        background-color: #f72585;
        color: #fff;
        padding: 1px 3.25px;
        border: 2px dashed #ffc400;
        border-radius: 7px;
        margin: 0 5px;
        cursor: pointer;
      }
    .qualified-doctors-section{
        margin: 1rem;
        margin-top: 3rem;
    }
    .about-us-section{
        margin: 1rem;
    }
    .main-title{
        text-align: center;
    }
    .section-title{
        font-size: 1.2rem;
    }
    
    .content{
        font-size: 1.15rem;
    }
    
    .como-fuciona-text{
        font-size: 1.15rem;
    }
}



.section-li{
    list-style: none;
}

.main-title{
    font-weight: 700;
}