.promotion-banner-container{
    background-color: #342065;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8vh;
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;
}
.promotion-banner-container p{
    color: white;
    font-size: 1rem;
    text-align: center;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .promotion-banner-container{
        padding: 2rem;
        justify-content: flex-start;
    }
}

.promotion-banner-container.scrolled p {
    width: 80%;
  }