.psicologo-agendarBtn-container{
    background-color: white;
    width: 100%;
    height: 11vh;
    position: sticky;
    z-index: 2;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.psicologo-agendarBtn-container .btn-agendar{
    width: 20rem;
    height: 2.5rem;
}

.btn-agendar{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-agendar i{
    font-size: 1.2rem;
    margin-right: 0.5rem;
}



.psicologo-section-container{
    min-height: 100%;
    width: 100%;
}

.psicologo-info-items-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 75%;
    margin: auto;

}

.psicologo-info-item{
    width: 100%;
    min-height: 12rem;
    margin: 0.15rem 0rem;
    background-color: white;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
}
.general{
   margin-top: 0.1rem;
   min-height: 8rem;
    height: auto;
}
.psicologo-info-header{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.psicologo-info-header-primary{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.psicologo-info-header-img{
    margin-right: 1rem;
}
.psicologo-info-header-img img{
   height: 5rem;
   width: 5rem;
   object-fit: cover;
}

.psicologo-info-header-text  h5 {
    margin:0;
}

.psicologo-info-header-text  p {
    margin:0;
}


.psicologo-info-header-secondary{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.psicologo-info-header-secondary p{
    margin: 0;
    margin-right: 1rem;
    font-size: 0.9rem;
}


.psicologo-info-container{
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.psicologo-info-title{
    display: flex;
    justify-content: flex-start;
}

.psicologo-info-title h6{
    color: rgb(63, 63, 63);
    font-weight: 600;
}

.psicologo-info-title i{
    color: #6E32FA ;
    font-size: 1.5rem;
    margin-right: 0.5rem;
}
.psicologo-info-content{
    padding-top: 0.5rem;
}

.psicologo-info-content p{
    font-size: 0.95rem;
}

.psicologo-info-content textarea{
    height: 10rem;
}

.especialidades {
    display: flex;
    flex-wrap: wrap;
}

.especialidad-item{
    margin-right: 0.5rem;
}

.opiniones {
    height: auto;
}

.opiniones-item i{
    color: #6E32FA;
    font-size: 1.2rem;
}

.opiniones-item{
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0rem;
}

.opiniones-item::after {
    content: "";
    border: 0.5px solid #d3d3d3;
    align-self: stretch;
}

.opiniones-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0.5rem 0rem;
}
.opiniones-header p{
    margin: 0;
    margin-left: 0.5rem;
    font-size: 0.9rem;
}
.opiniones-body p{
    margin: 0;
    font-size: 0.9rem;
}
.opiniones-body{
    margin-bottom: 0.6rem;
    word-wrap: break-word;
}

.idiomas{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.idiomas p{
    margin: 0;
    margin-left: 0.5rem;
}

.psicologo-info-edit{
    display: flex;
}

.idiomas-container-edit{
    display: flex;
    flex-direction: row;    
    justify-content: space-between;
}

@media screen and (max-width: 930px){
    .psicologo-info-items-container{
        width: 90%;
    }
    .psicologo-info-container{
        width: 80%;
    }
 }

@media screen and (max-width: 609px){
    .psicologo-agendarBtn-container{
        height: 12vh;
    }
    .psicologo-agendarBtn-container button{
        font-size: 0.8rem;
    }
    .psicologo-info-content p{
        font-size: 1rem;
    }
    .psicologo-info-title h6{
        font-size: 1.1rem;
    }
 }