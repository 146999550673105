.datos-container{
    margin: auto;
    max-width: 80%;
    height: auto;
    padding: 2rem;
    margin-top: 1.5rem;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-shadow: 0 0 10px rgba(0,0,0,0.1) !important;
}

.btnActualizar{
    color: white !important;
    max-width: 10rem;
    margin: 0rem 1rem;
}
.btnActualizar-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
}

.user-image{
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-image img{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    object-fit: cover;
}

.btn-logout{
    height: fit-content;
    width: 9rem;
    display: flex;
    align-items: center;
}
.btn-logout i{
    margin-right: 0.3rem;
}

.modal-dialog{
    width: 40% !important;
 }


@media screen and (max-width: 930px){
    .datos-container{
        max-width: 95%;
        margin: 2rem;
    }
    .btn-logout{
        max-height: 4rem;
        font-size: 1rem;
     }
    .btn-logout i{
       display: none;
    }
    .modal-dialog{
        width: initial !important;
     }
     .miCuenta-container input{
        font-size: 0.8rem;
     }
 }

