
  .pricing {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
    /* margin: 0 auto 3em; */
    margin-top: 2rem;
    border-bottom: 0px !important;
  }
  .pricing-item {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
    text-align: center;
    -webkit-flex: 0 1 380px;
    flex: 0 1 380px;
  }
  .pricing-action {
    color: inherit;
    border: none;
    background: none;
  }
  .pricing-action:focus {
    outline: none;
  }
  
  .pricing-feature-list {
    text-align: left;
  }
  
  .pricing-palden .pricing-item {
    cursor: default;
    color: #6c757d;
    background: #fff;
    box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
    border-radius: 20px 20px 20px 20px;
    margin: 1em;
  }
  
  @media screen and (min-width: 66.25em) {
    .pricing-palden .pricing-item {
      margin: 1em -0.5em;
    }
  
    .pricing-palden .pricing__item--featured {
      margin: 0;
      z-index: 10;
      box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
    }
  }
  .pricing-palden .pricing-deco {
    border-radius: 20px 20px 0 0;
    background: rgba(103, 48, 236, 0.87) !important;
    padding: 4em 0 6em;
    position: relative;
  }
  .pricing-palden .pricing-deco-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5rem;
  }
  .pricing-palden .pricing-title {
    font-size: 0.75em;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #fff;
  }
  .pricing-palden .deco-layer {
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
  }
  .pricing-palden .pricing-item:hover .deco-layer--1 {
    -webkit-transform: translate3d(15px, 0, 0);
    transform: translate3d(15px, 0, 0);
  }
  .pricing-palden .pricing-item:hover .deco-layer--2 {
    -webkit-transform: translate3d(-15px, 0, 0);
    transform: translate3d(-15px, 0, 0);
  }
  .pricing-palden .icon {
    font-size: 2.5em;
  }
  .pricing-palden .pricing-price {
    font-size: 4em;
    font-weight: bold;
    padding: 0;
    color: #fff;
    margin: 0 0 0.25em 0;
    line-height: 0.75;
  }
  .pricing-palden .pricing-currency {
    font-size: 0.15em;
    vertical-align: top;
  }
  .pricing-palden .pricing-period {
    font-size: 0.15em;
    padding: 0 0 0 0.5em;
    font-style: italic;
  }
  .pricing-palden .pricing__sentence {
    font-weight: bold;
    margin: 0 0 1em 0;
    padding: 0 0 0.5em;
  }
  .pricing-palden .pricing-feature-list {
    margin: 0;
    padding: 0.25em 0 1.5em;
    list-style: none;
    text-align: center;
  }
  .pricing-palden .pricing-feature {
    padding: 0.5em 0;
  }
  .pricing-palden .pricing-action {
    font-weight: bold;
    margin: auto 3em 2em 3em;
    padding: 1em 2em;
    color: #fff;
    border-radius: 30px;
    background: #6E32FA;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .pricing-palden .pricing-action:hover, .pricing-palden .pricing-action:focus {
    background-color: #4C23AD;
  }
  
