.misHorarios-section{
    padding: 2rem;
}

.misHorarios-container{
    margin: auto;
    max-width: 75%;
    min-height: 29rem;
    padding: 2rem;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.misHorarios-form-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.misHorarios-title{
    align-self: flex-start;
}

.misHorarios-days{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 3rem auto;
    flex-wrap: wrap;
}

.misHorarios-day{
    background-color: #6e32fa2a;
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0rem 0.2rem;
}

.misHorarios-day:hover{
    background-color: #6e32fa7d;
    cursor: pointer;
}

.misHorarios-day.active{
    background-color: #6e32fa98;
}

.misHorarios-day p{
    font-size: 1rem;
    font-weight:400;
    margin: 0;
}

.misHorarios-button-container{
    align-self: flex-end;
    margin: auto;
}

.misHorarios-summary-container{
    width: 100%;
    height: 100%;
    margin-bottom: 3rem;
}
.misHorarios-summary-text{
    max-width: max-content;
    background: rgb(243, 243, 243) !important;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid rgb(214, 214, 214);
    margin: auto;
    overflow-wrap: break-word;
}
.misHorarios-summary-text p{
    margin: 0;
    font-size: 0.85rem;
    font-weight: 500;
    word-wrap: break-word;
 }


 .misDescansos-container{
    margin-top: 2rem;
    width: 100%;
 }

 .misDescansos-cards{
    display: grid;
    gap: 1rem;
 }

 .dayOfWeek-container{
    min-height: 50px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-auto-flow: column;
    gap: 50px;
    justify-content: flex-start;
    height: 100%;
    align-items: flex-start; 
 }

 .descanso-inputs{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
 }
 .descanso-inputs p{
    margin: 0;
    padding: 0;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
 }
 .descanso-inputs select{
    padding: 0.5rem;
    width: 5rem;
    font-size: 0.7rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
 }

 .dayOfWeekInput{
    width: 4rem;
    height: 2rem;
    font-size: 0.7rem;
 }

 .btn-agregarDescanso{
    font-size: 0.7rem;
    padding: 0.5rem;
    text-wrap: nowrap;
 }

 .btn-guardarDescanso{
    font-size: 0.7rem;
    padding: 0.5rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    
 }

 .btn-eliminarDescanso{
    font-size: 0.7rem;
    padding: 0.5rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
 }

 .dayOfWeek-text{
    min-width: 4.5rem;
 }

 .descansoComponentInputs-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
 }

 @media screen and (max-width: 1380px){
    .misHorarios-section{
        padding: 0.5rem;
    }
    .dayOfWeek-container{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
     }
     .btn-eliminarDescanso{
        font-size: 0.6rem;
        padding: 0.4rem;
        margin-right: 0.25rem;
     }
     .btn-guardarDescanso{
        font-size: 0.6rem;
        padding: 0.4rem;
        margin-right: 0.25rem;
     }
 }


 @media screen and (max-width: 930px){
    .misHorarios-container{
        max-width: 95%;
    }
    .tab-switcher{
        width: 100% !important;
        text-align: center;
    }
 }

 @media screen and (max-width: 609px){
    .misHorarios-container{
        max-width: 95%;
    }
    .misHorarios-summary-container{
        display: none;
    }
    .misHorarios-button{
        font-size: 1rem;
    }
    .misHorarios-container svg{
        width: 300px !important;
    }
 }