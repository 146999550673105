.thank-you-container{
    margin: auto;
    max-width: 80%;
    height: auto;
    padding: 3rem;
    margin-top: 1.5rem;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
}