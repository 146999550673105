.onBoarding iframe {
    width: 100vw !important;
    height: 100vh !important;
    overflow: scroll !important;
}

.onBoarding {
    overflow: scroll !important;
}

body {
    overflow-y: scroll !important;
}