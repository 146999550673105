.psi-landing-about{
    margin-bottom: 100px;
    padding: 0 2rem;
}

.unirseBtn{
    font-size: large;
}

.features-title{
    margin: 3rem 0rem;
}

.psicologo-system-img{
    height: 25rem;
}

.psi-landing-img{
    height: 21rem;
}

@media screen and (max-width: 768px) {
    .psi-landing-img{
        height: 20rem;
        margin-top: 4rem;
    }
    .unirseBtn{
        margin-left: 1rem;
    }
    .psi-landing-about{
        margin-top: 0 !important;
    }
}
    