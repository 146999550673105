.singup-section{
    height: 100%;
    width: 100%;
    background-color: rgb(239, 239, 239);
    padding: 2rem;
}

.singup-form-container{
    max-width: 75%;
    min-height: 95%;
    margin: 0 auto;
    padding: 2rem;

    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}

.singup-form-container .btnActualizar-row{
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.singup-form-header{
    align-self: flex-start;
}

#calendar{
    font-size: 0.8rem !important;
}


 @media screen and (max-width: 930px){
    .singup-form-container{
        max-width: 97%;
        padding: 2rem;
    }
 }