

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 780px) {

  
    .payment-form-container form {
      width: auto !important;
      min-width: initial !important; 
    }
    
    .padding-form-items{
      width: 90%;
      padding: 1rem;
    }
}
  
  
@media screen and (max-width: 780px) {
    .payment-form-container form {
      width: auto !important;
      min-width: initial !important; 
    }
    
    .padding-form-items{
      width: 90%;
      padding: 1rem;
    }
}
  
@media screen and (max-width: 430px) {
  .home{
    left: 0 !important;
    width: 100% !important;
  }
  .main-head{
    flex-direction: flex-start;
  }
  .payment-form-container {
    max-width: 93% !important;
  }
  .padding-form-items{
    padding: 2rem;
  }
  .payment-form-container form {
    padding : 1rem !important;
  }
  .payment-form-container h6 {
    padding : 0.3rem 0rem !important;
  }

}

.checkout-container{
  height: 91vh;
  background-color: white;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */}


.payment-form-container{
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    height: fit-content;
    width: 100vw;
    
    margin: auto;
    max-width: 80%;
    height: auto;
    padding: 1.1rem;
    margin-top: 0.8rem;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.payment-form-container h6 {
    font-family: 'Krub', sans-serif;
    color: #707070;
    align-self: center;
    margin-bottom: 1rem;
}

.payment-form-container form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
}

.payment-form-container .payment-message {
    font-size: 0.9rem;
}

.payment-form-container #payment-element {
    margin-bottom: 24px;
  }

.payment-form-container button {
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}

.payment-form-container button:hover {
    filter: contrast(115%);
  }

  .payment-form-container button:disabled {
    opacity: 0.5;
    cursor: default;
  }

  /* Payment method */

.payment-method-container{
  margin: 2rem 0rem;
}
.payment-method-container .card{
  width: 80%;
  margin: auto;
}


.btn-container{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.btn-container button{
  width: 48%;
  line-height: 1.2rem;
}

.btn-container .button-text{
  font-size: 0.7rem;
}
#button-text{
  display: flex;
    justify-content: center;
    align-items: center;
}
.button-text-spinner{
  min-width: 8rem;
  min-height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

